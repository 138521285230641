// import ReferenceCell from '../referenceCell'

export default [
    {
      title: "Emp Code",
      key: "empcode",
      minWidth: 80,
      width: 50,
    },
    {
      title: "Grade",
      key: "grade_id",
      minWidth: 80,
      width: 40,
    },
    {
      title: "Name Of Employee",
      key: "NAME",
      //Mahima on 19-07-2023: This will be needed to check the user details, cmmented as I don't have the corresponding SP right now.
      // render: (h, params) => {
      //     return h(ReferenceCell, {
      //         props: {
      //             row: params.row
      //         }
      //     });
      // },
      minWidth: 80,
      width: 150,
    },
    {
      title: "Balance CL",
      key: "Balance_CL",
      // sortable: true,
      minWidth: 80,
      width: 60,
    },
    {
      title: "Balance EL",
      key: "Balance_EL",
      // sortable: true,
      minWidth: 80,
      width: 60,
    },
    {
      title: "Absent Dates",
      key: "Absent_dates",
      // sortable: true,
      minWidth: 80,
      width: 150,
    },
    {
      title: "Total Absent Days",
      key: "Total_absent_days",
      // sortable: true,
      minWidth: 80,
      width: 60,
    },
    {
      title: "Late Coming Dates",
      key: "Late_coming_dates",
      // sortable: true,
      minWidth: 80,
      width: 60,
    },
    {
      title: "Leave DLC",
      key: "leave_due_to_late",
      // sortable: true,
      minWidth: 80,
      width: 60,
    },
    {
      title: "Approved Leave",
      key: "Approved_leave",
      // sortable: true,
      minWidth: 80,
      width: 80,
    },
    {
      title: "Not Approved Leave",
      key: "not_approved_leave",
      // sortable: true,
      minWidth: 80,
      width: 80,
    },
    {
      title: "Net Deduction",
      key: "net_deduction",
      // sortable: true,
      minWidth: 80,
      width: 80,
    },
    {
      title: "Carry F/W CL",
      key: "carry_forward_CL",
      // sortable: true,
      minWidth: 80,
      width: 80,
    },
    {
      title: "Carry F/W EL",
      key: "carry_forward_EL",
      // sortable: true,
      minWidth: 80,
      width: 80,
    },
    {
      title: "Net Pay Days",
      key: "Net_pay_days",
      // sortable: true,
      minWidth: 80,
      width: 80,
    },
];
