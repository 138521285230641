<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Monthly Attendance Report" slot="title" link="/helpContent/Monthly Attendance Report" />
    <ValidationObserver ref="validator">
      <FormRow>
          <div class="col-sm-3">
              <FormSelect label="Company" :items="companyList" item-name="Name" item-value="id" v-model="filtering.selectedCompanyId" rules="required"/>
          </div>
          <div class="col-sm-2">
              <Year label="Year" v-model="filtering.selectedYear" rules="required" />
          </div>
          <div class="col-sm-2">
              <InputMonth label="Month" v-model="filtering.selectedMonth" rules="required" />
          </div>
          <div class="col-sm-5">
              <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                  <Icon type="ios-search" />
              </FormButton>
          </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="listData"
      :columns="tableConfig"
      :is-loading="isLoading"
    
      >
      <!-- @on-selection-change="selectChange"
      :pagination="pagination"
      @on-page-change="pageChange"
      @on-sort-change="sortChange" -->
      <template slot="actionsLeft">
        <TableButton @click="() => freeze()">
          <img :src="require(`Assets/images/lock.png`)"  />
          Freeze Month
        </TableButton>
        <!-- sagar add export excel button30-08-2023 -->
        <TableButton @click="() => exportData()">
      <img :src="require(`Assets/images/Excel_Icon.png`)"  />
      Export
    </TableButton>
      </template>
    </DataTable>
    <Popup title="Information" :value="warningModal.visible" @close="() => showWarningModal(false)">
        {{ warningModal.message }}
        <div slot="footer">
            <FormButton v-if="warningModal.buttons.cancel" @click="() => showWarningModal(false)">Cancel</FormButton>
            <FormButton v-if="warningModal.buttons.confirm" type="success" @click="confirmFreeze">Confirm</FormButton>
            <FormButton v-if="warningModal.buttons.ok" @click="() => showWarningModal(false)">Ok</FormButton>
        </div>
    </Popup>
    <Popup title="Success" type="success" :value="showMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import DataTable from "Components/DataTable";
  import Year from "../../../components/Year";
  import loadingMixin from "Mixins/loadingMixin";
  import { getAllCompanyList, getMonthlyAttendanceReportHR, freezeAttendance } from "../api";
  import tableConfig from "./table";
  import validateSelectedRows from "Mixins/validateSelectedRows";
  import { ValidationObserver } from "vee-validate";
  export default {
    name: "List",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
      FormWrapper,
      DataTable,
      PageTitle,
      Year,
      ValidationObserver,
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    data() {
      return {
        filtering: {
          selectedCompanyId: null,
          selectedYear: new Date().getFullYear(),
          selectedMonth: new Date().getMonth() + 1,
        },
        companyList: [],
        sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
        tableConfig,
        listData: [],
        showMessage: {
          isVisible: false,
          message: "",
        },
      };
    },
    created() {
      this.getAllList();
    },
    methods: {
      async getAllList() {
        getAllCompanyList().then((res) => {
          this.companyList = res.data;
        });
      },
      search() {
        this.pagination.page = 1;
        this.getDataByFilter();
      },
      selectChange(data) {
        this.selectedRows = data;
      },
      sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
      freeze(){
        this.$refs.validator.validate().then((result) => {
          if (result  && this.listData.length>0) {
            if (this.freezeTheData()) {
            }
          }
        });
      },
      freezeTheData(value) {
        this.warningModal.message = 'Are you sure you want to freeze the data.';
        this.warningModal.buttons.ok =false;
        this.warningModal.buttons.cancel =true;
        this.warningModal.buttons.confirm =true;
        this.showWarningModal(true);
        return false
      },
      confirmFreeze(){
        this.showWarningModal(false);
        const data = {
          // staffId: this.userData.user_id,
          year: this.filtering.selectedYear,
          month: this.filtering.selectedMonth
        };
        //This SP return error as it doen't work. DB team is checking the issue
        freezeAttendance(data).then((res) => {
          if (res.data[0].status > 1) {
            this.showWarningModal(true);
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.warningModal.message = res.data[0].message;
            this.selectedRows = [];
          } else {
            this.selectedRows = [];
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          }
        });
      },
      getDataByFilter() {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            setTimeout(() => {
              this.showLoader();
              const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;
              const filters = {};
              filters.companyId = this.filtering.selectedCompanyId;
              filters.year = this.filtering.selectedYear;
              filters.month = this.filtering.selectedMonth;
              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  // paging: [
                  //   {
                  //     startIndex: page,
                  //     pagesize: perPage,
                  //   },
                  // ],
                },
              };
              getMonthlyAttendanceReportHR(data)
                .then(this.handleResponse)
                .catch(this.handleError);
            }, 50);
          }
        });
      },
      pageChange(page) {
        this.pagination.page = page;
        this.getDataByFilter();
      },
      handleResponse(response) {
        this.hideLoader();
        this.listData = response.data;
        this.pagination.total = response.NumberOfRecords;
      },
      exportData() {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            setTimeout(() => {
              this.showLoader();
              const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;
              const filters = {};
              filters.companyId = this.filtering.selectedCompanyId;
              filters.year = this.filtering.selectedYear;
              filters.month = this.filtering.selectedMonth;
              filters.excel =1;
              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  // paging: [
                  //   {
                  //     startIndex: page,
                  //     pagesize: perPage,
                  //   },
                  // ],
                },
              };
              getMonthlyAttendanceReportHR(data)
                .then(this.handleExportResponse)
                .catch(this.handleError);
            }, 50);
          }
        });
      },
      handleExportResponse(response) {
        this.hideLoader();
          window.open(response.data.url,'_blank');
      },
    },
  };
</script>

<style lang="scss" module>
  .popupRow {
    padding: 5px 0 15px;
  }

  .pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
  }

  .paginationText {
    margin: 0 20px;
  }

  .paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      box-shadow 200ms ease;
  }

  .paginationButton:hover {
    background: #57a3f3;
    color: #fff;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }
</style>